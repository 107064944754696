import React from 'react';

import 'moment/locale/ru';
import locale from 'antd/lib/locale/ru_RU';
import 'antd/dist/antd.css';
import './App.css';

import Calc		                   from './components/Calc';

import { ConfigProvider, message, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

class App extends React.Component {
  constructor(props) {
		super(props);

    this.state = {
      error: ['',''],
      proj: [0,[]],
      moreload: false,
      loading: true,
      app: {}
    };
  }

  //* Вызов сразу после рендера
  async componentDidMount() {
    let app = this.state.app;
    if (document.domain === 'localhost') {
      app['url'] = 'https://calc.rus2.ru';
    } else {
      app['url'] = `https://${document.domain}`;
    }
    await this.setState( { app:app } );
    await this.api({ 'method':'calc' },true);
  }

  //* POST запрос
  postRequest(url, data) {
    return fetch(url, {
      credentials: 'same-origin',
      method: 'POST',
      mode: 'cors',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
    })
    .then(response => response.json())
  }

  //* Получение информации
  async api(v,s) {
    if (s) {
      await this.setState( { loading: true, moreload:false } );
    } else {
      await this.setState( { moreload:false } );
    }
    
    await this.postRequest(`${this.state.app.url}/api_calc/`, v)
    .then(async json => {
      var data = json;
      if(data.code) {
        let app          = ( data.app ) ? data.app : [];
        await this.setState( {
          app: app,
        } );

        //* Методы
        switch(v['method']) {
          case 'calc':
            var proj = this.state.proj;
            var list 		= ( data.list ) ? data.list : [];
            proj[1] = list;
            await this.setState( { proj:proj } );
            break;
          default: break;
        }
      }
      if(data.error) {
        await this.setState( { error: data.error } );
        message.error(`${data.error[0]}${data.error[1].length > 0 ? ` - ${data.error[1]}`:''}`);
      }
    })
    .catch( async err => {
      await this.setState( { error: ['Проблемы со связью',''] } );	//Проблемы со связью
    });
    if (s) {
      await this.setState( { loading: false, moreload: true } );
    } else {
      await this.setState( { moreload:true } );
    }
  }

  decnum (a,b) { let cases=[2,0,1,1,1,2];return b[(a%100>4 && a%100<20)?2:cases[(a%10<5)?a%10:5]]; }

  //* Рендер
  render() {
    const spin = <div className="spincenter"><Spin indicator={<LoadingOutlined style={{ fontSize: 64,color:'#6f70cc' }} spin />} /></div>;

    return (
      <ConfigProvider locale={locale}>
        {!this.state.loading ? <Calc state={this.state} setState={this.setState.bind(this)} />:spin}
      </ConfigProvider>
    );
  }
}

export default App;
