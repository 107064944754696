import React from 'react';
import { Form, InputNumber, Button, Switch, message, Badge, Table, Empty, Typography, Row, Col } from 'antd';
import {  } from '@ant-design/icons';

const { Text } = Typography;

const columns1 = [
    {
        title: 'Услуга',
        dataIndex: 'name',
        key: 'name',
        width: '50%',
    },{
        title: 'Количество',
        render: (record,index,rowIndex) => (
            record.type === '2' ? 
                <Form.Item name={rowIndex}>
                    <Switch data-index={rowIndex} defaultChecked={Number(record.count)}></Switch>
                </Form.Item>
                
            :
                <Badge count={Number(record.min) ? `от ${record.min}` : record.min} style={{ backgroundColor: '#6f70cc',boxShadow: 'rgb(54 55 121) 0px 0px 0px 1px' }}>
                    <Form.Item name={rowIndex}>
                    <InputNumber bordered={false} min="0" data-index={rowIndex} defaultValue="0" style={{ width: '100%' }} />
                </Form.Item>
                </Badge>
                
        ),
        width: '25%',
    },{
        title: 'Стоимость',
        dataIndex: 'price',
        key: 'price',
        width: '25%',
        render: (text) => `${text} ₽`
    },
];

const Global = props => {
    const [form] = Form.useForm();

    //* Калькулятор
    async function calctheme(v) {
        console.log(v);
        var full = 0;
        var info = props.state.proj[1];
        for(var x = 0; x<= Object.keys(v).length; x++) {
            if (v[x] >= 0) {
                //@ SWITCH
                if (v[x] === true) {
                    full += Number(info[x].price);
                    info[x].count = 1;
                    info[x].cost = Number(info[x].price);
                } else {
                    //@ INPUT
                    if (Number(v[x]) > 0) {
                        if (Number(v[x]) < info[x].min) {
                            v[x] = 0;
                        }
                        full += Number(v[x])*Number(info[x].price);
                        info[x].count = Number(v[x]);
                        info[x].cost = Number(v[x])*Number(info[x].price);
                    } else {
                        info[x].count = 0;
                        info[x].cost = 0;
                    }
                }
            }
        }
        let proj = props.state.proj;
        proj[0] = full;
        proj[1] = info;
        message.success(`Итоговя стоимость ${full} ₽`);
        await props.setState( { proj:proj } );
        form.setFieldsValue(props.state.proj[1].map((info) => info.count ));
    }

    return  (
        <Form name="form" onFinish={calctheme} form={form} className="form">
            <Table 
                locale={{ emptyText: <Empty description="Список пуст" />}} 
                dataSource={props.state.proj[1]} 
                scroll={{ x: 350 }} 
                onRow={(record, index) => ({index})} 
                pagination={false} 
                rowKey="table2" 
                columns={columns1} 
                title={() => 
                    <Row>
                        <Col span={12} style={{display:'flex',alignItems:'center'}}>
                            <Text><Text strong>Стоимость: </Text>{props.state.proj[0]} ₽</Text>
                        </Col>
                        <Col span={12}>
                            <Form.Item><Button style={{display:'flex',marginLeft:'auto'}} type="primary" shape="round" htmlType="submit">Рассчитать</Button></Form.Item>
                        </Col>
                    </Row>
                }
                footer={() => 
                    <Row>
                        <Col span={12} style={{display:'flex',alignItems:'center'}}>
                            <Text><Text strong>Стоимость: </Text>{props.state.proj[0]} ₽</Text>
                        </Col>
                        <Col span={12}>
                            <Form.Item><Button style={{display:'flex',marginLeft:'auto'}} type="primary" shape="round" htmlType="submit">Рассчитать</Button></Form.Item>
                        </Col>
                    </Row>
                }
                bordered={true}
            >
            </Table>
        </Form>
    )
};

export default Global;